 <template>
  <section class="circle-card">
    <editCard
      v-if="editable"
      :modal_name="'ccmeb' + sectionTitle"
      :entitytypes="['products', 'categories', 'services']"
      @edit-card-com="changeOption($event)"
      :option="options"
      :parentData="pdata"
    />
    <!-- affichage des cards -->
    <div class="row product-item-wrapper">
      <div :class="{ scrollcards: scrollcards }" :id="id">
        <div
          class="
            col-lg-4 col-md-6 col-sm-6 col-12
            mx-2
            product-item
            stretch-card
          "
          v-for="(item, itemID) in items"
          :key="itemID"
        >
          <slot></slot>
          <!-- affichage des cards -->
          <div class="card position-relative" style="width: 18rem">
            <img
              v-if="entitytype"
              class="card-img-top"
              src="https://picsum.photos/200"
              alt="Card image cap"
            />
            <img
              v-else
              class="card-img-top"
              :src="item[image]"
              alt="Card image cap"
            />

            <!-- suppression d'un card individuel-->
              <button v-if="editable && !entitytype" class="btn btn btn-gradient-danger btn-rounded btn-icon btn-danger deleteCard" @click="deleteCard(item)"> <i class="fa fa-times"></i></button>

            <!-- card edit image -->
            <div class="editimg" v-if="editable">
              <add-image
                :modal="'addImage' + itemID + sectionTitle"
                @saveImage="saveImage(item, $event['image'])"
              />
            </div>
            <!--end card edit image -->

            <!-- modal edit photo  -->
            <b-modal
              :id="'photo-card-edit-modal' + sectionTitle + itemID"
              ref="modal"
              title="Change picture"
            >
              <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group
                  label="Url"
                  label-for="name-input"
                  invalid-feedback="Name is required"
                >
                  <b-form-input v-model="item[image]" required></b-form-input>
                </b-form-group>
              </form>
            </b-modal>
            <!-- End modal edit photo  -->

            <!-- text -->
            <div class="card-body">
              <span v-if="!entitytype && editable && isDisplay('title')">
                <input class="h5" v-model="item[title]" />
              </span>
              <span v-else>
                <h5 v-if="isDisplay('title') && item[title]">
                  {{ item[title] }}
                </h5>
              </span>

              <!-- si c'est une collection; le titre, la description et les images ne sont pas modifiables -->
              <br />
              <span v-if="!entitytype && editable && isDisplay('description')">
                <input v-model="item[description]" />
              </span>
              <span class="text-muted" v-else>
                <p v-if="isDisplay('description') && item[description]">
                  {{ item[description] }}
                </p>
              </span>
            </div>
          </div>
        </div> <!-- fin du v-for -->

        <!-- ajout d'un nouveau card -->
        <div
        v-if="editable && !entitytype"
        class="col-lg-4 col-md-6 col-sm-6 col-12 mx-2 product-item stretch-card clickable"
        @click="cardPush()"
        title="Ajouter une nouvelle carte"
        >
          <div class="card position-relative" style="width: 18rem">
              <img class="card-img-top" src="@/assets/images/import/add-picture.png" alt="ajouter un nouveau card">
              <!-- suppression -->
              <!-- <button class="btn btn btn-gradient-danger btn-rounded btn-icon btn-danger deleteCard"> <i class="fa fa-times"></i></button> -->
              <div class="card-body">
                  <h5 class="card-title text-center">Ajouter</h5>
              </div>
          </div>
        </div>
      </div>
      <!-- ajout d'un nouveau card individuel -->
      <!-- <a v-if="editable && !entitytype" class="" @click="cardPush()">
        <label class="badge badge-gradient-warning" style="z-index: 1"
          ><i class="fa fa-plus"></i
        ></label>
      </a> -->
    </div>
  </section>
</template>

<script>
import products from "../../pages/json/products-reference-data.json";
import services from "../../pages/json/services-reference-data.json";
import addImage from "../functions/add-image.vue";
import editCard from "./edit-card.vue";
// import stringText from "./string-text.vue";

import axios from "axios";
export default {
  name: "circle-card",
  components: {
    // stringText,
    addImage,
    editCard,
  },
  data() {
    return {
      items: [],
      selectedDisplay: [],
      scroll_card: null,
      key: 1,
      products: products,
      services: services,
      coll_Checked: null,
      sel_entity: null,
      options: {
        collection: true,
        display: [
          { titre: "Description", value: "description" },
          { titre: "Titre", value: "title" },
        ],
      },
     pdata:{
       selentity:this.entitytype,
       sdisplay:this.display,
       scroll:this.scrollcards
     }
    };
  },
  props: {
    id: String,
    scrollcards: Boolean,
    entitytype: String,
    card: Array,
    title: String,
    description: String,
    price: String,
    column: String,
    sectionTitle: String,
    editable: Boolean,
    image: String,
    display:Array,
  },
  methods: {
    cardPush() {
      let event = {
        "card-image-url":
          "https://edito.regionsjob.com/xjob/wp-content/uploads/sites/3/2020/06/metiers-massage.jpg",
        "card-title": " Simple titre",
        "card-description": "test description",
      };
      this.$emit("push-new-card-meb", event);
    },
    changeOption(data) {
      (this.selectedDisplay = data["display"]),
        (this.coll_Checked = data["collection"]),
        (this.sel_entity = data["entity"]),
        (this.scroll_card = data["scroll"]);
      this.saveCard();
    },
    isDisplay(el) {
      if (this.selectedDisplay.includes(el)) {
        return true;
      } else {
        return false;
      }
    },
    getEntitytype() {
      if (this.entitytype) {
        axios
          .get(`${this.entitytype}`)
          .then((resEntitytype) => {
            this.items = resEntitytype.data.result;
            console.log("entitytype V4", this.items);
          })
          .catch((errEntitytype) => {
            console.log(errEntitytype);
          });
      } else if (this.card) {
        this.items = this.card;
      } else {
        this.items = [];
      }
    },
    saveImage(item, i) {
      item[this.image] = i;
    },
    textTypographie(elem, data) {
      if (data["type"] == "size") {
        elem.data["paragraph-font-size"] = data["value"];
      }
      if (data["type"] == "justify") {
        elem.data["paragraph-font-justify"] = data["value"];
      }
      if (data["type"] == "weight") {
        elem.data["paragraph-font-weight"] = data["value"];
      }
      if (data["type"] == "style") {
        elem.data["paragraph-font-style"] = data["value"];
      }
    },
    //scrollcards
    scrollright() {
      console.log(this.id);
      document.getElementById(`${this.id}`).scrollLeft += 1000;
    },
    scrollleft() {
      console.log(this.id);
      document.getElementById(`${this.id}`).scrollLeft -= 1000;
    },
    deleteCard(item) {
      let index = this.items.indexOf(item);
      console.log(index);
      this.items.splice(index, 1);
    },

    saveCard() {
      if (!this.coll_Checked) {
        //si individuel
        this.$emit("saveCard", {
          section: {
            title: "nouvel ajout",
            blocks: [
              {
                element: "circle-card-meb",
                entitytype: "",
                data: {
                  display:this.selectedDisplay,
                  card: [
                    {
                      "card-image-url":
                        "https://edito.regionsjob.com/xjob/wp-content/uploads/sites/3/2020/06/metiers-massage.jpg",
                      "card-title": " Simple titre",
                      "card-description": "test description",
                    },
                  ],
                },
              },
            ],
          },
          blocks: [
            {
              element: "circle-card-meb",
              entitytype: "",
              data: {
                scrollcards: this.scroll_card,
                display:this.selectedDisplay,
                card: [
                  {
                    "card-image-url":
                      "https://edito.regionsjob.com/xjob/wp-content/uploads/sites/3/2020/06/metiers-massage.jpg",
                    "card-title": " Simple titre",
                    "card-description": "test description",
                  },
                ],
              },
            },
          ],
          key: this.key + 1,
        });
      } else {
        //si collection
        this.$emit("saveCard", {
          section: {
            title: "ajout de collection",
            blocks: [
              {
                element: "circle-card-meb",
                entitytype: this.sel_entity,
                data: {
                  scrollcards: this.scroll_card,
                  display:this.selectedDisplay,
                  collection: {
                    "card-image-url": "image",
                    "card-title": "name",
                  },
                },
              },
            ],
          },
          blocks: [
            {
              element: "circle-card-meb",
              entitytype: this.sel_entity,
              data: {
                scrollcards: this.scroll_card,
                display:this.selectedDisplay,
                collection: {
                  "card-image-url": "image",
                  "card-title": "category",
                },
              },
            },
          ],
        });
      }
    },
  },
  mounted() {
    this.getEntitytype();
    this.selectedDisplay = this.display
  },
};
</script>

<style scoped>
input {
  border: none;
}
.product-item {
  display: inline-block;
  text-decoration: none;
  height: auto;
  width: 288px;
}

/* scrollcards */
.scrollcards {
  /* background-color: #fff; */
  overflow-x: auto;
  white-space: nowrap;
}

/* ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
} */

.scrollcards {
  scrollbar-width: thin;
}

div.scrollcards .card {
  display: inline-block;
  /* padding: 14px; */
  text-decoration: none;
  height: auto;
  width: 300px;
}

.carousel-control-prev-icon {
  background-image: url("../../assets/images/import/arrow-preview.png");
  width: 50px;
  height: 50px;
  margin: 0px 81px 0px -122px;
}
.carousel-control-next-icon {
  background-image: url("../../assets/images/import/arrow-next.png");
  width: 50px;
  height: 50px;
  margin: 0px -121px 0px 79px;
}

.card {
  width: 18rem;
  border: none !important;
}

img {
  border-radius: 50%;
  width: 13rem;
  height: 200px;
  object-fit: cover;
}

.construct {
  color: black;
  height: 2.5rem;
  width: 2.5rem;
  position: absolute;
  right: 6rem;
  font-size: 1.3rem;
}
.editimg {
  position: absolute;
  bottom: 5rem;
  left: 5rem
}
.deleteCard {
  position: absolute;
  right: 6rem;
}
</style>